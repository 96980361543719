/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import { mergeCraftPreview, resolveImage, resolveBreaks, resolveMeta } from "@dataResolvers";
import { Posts as PageTemplate } from "@pageTemplates";

export const query = graphql`
query {
  craft {
    entries(section: "post", limit: 5, offset: 0) {
      ... on Craft_post_post_Entry {
        title
        descriptor0
        categories {
          ...on Craft_blogCategories_Category {
            title
            slug
          }
        }
        heroImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        url
        postDate
        authors {
          ... on Craft_authors_authors_Entry {
            title
            slug
          }
        }
      }
    }
    entry(section: "postIndex") {
      ... on Craft_postIndex_postIndex_Entry {
        #Meta
        title
        uid
        url
        slug
        metaTitle
        metaDescription
        metaImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        heroLabel
        heroHeading
      }
    }
  }
}
`;

const previewQuery = `
query {
  craft {
    entries(section: "post", limit: 5, offset: 0) {
      ... on Craft_post_post_Entry {
        title
        descriptor0
        categories {
          ...on Craft_blogCategories_Category {
            title
            slug
          }
        }
        heroImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        url
        postDate
        authors {
          ... on Craft_authors_authors_Entry {
            title
            slug
          }
        }
      }
    }
    entry(section: "postIndex") {
      ... on Craft_postIndex_postIndex_Entry {
        #Meta
        title
        uid
        url
        slug
        metaTitle
        metaDescription
        metaImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        heroLabel
        heroHeading
      }
    }
  }
}
`;

const dataResolver = ({ craft }) => {
  const {
    heroHeading,
    heroLabel,
    title,
    uid,
    url,
    slug,
    metaTitle,
    metaDescription,
    metaImage,
  } = craft.entry;

  return {
    meta: resolveMeta({
      title,
      metaTitle,
      metaImage,
      metaDescription,
      slug,
      url,
    }),
    hero: {
      label: heroLabel,
      heading: heroHeading,
    },
    posts: craft.entries.map(post => {
      return {
        title: post.title,
        description: post.descriptor0,
        category: post.categories[0].slug,
        image: resolveImage(post.heroImage),
        author: post.authors[0].title,
        date: post.postDate,
        link: post.url,
      }
    }),
  }
};

const QueryContainer = ({ data, pageContext }) => {
  const { slug: _slug } = pageContext;
  const merged = mergeCraftPreview(data, previewQuery, {
    slug: _slug,
  });

  // When previewing unpublished pages, merged will be empty until
  // the preview reqeust has finished, so returning null to avoid
  // component errors about missing content.
  if (!merged) {
    return null;
  }

  const resolvedData = dataResolver(merged);

  return <PageTemplate data={resolvedData} />;
};

export default QueryContainer;
